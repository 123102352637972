(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('product', {
            parent: 'entity',
            url: '/product?page&sort&search',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER'],
                pageTitle: 'product.home.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product/products.html',
                    controller: 'ProductController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,asc',
                    squash: true
                },
                field: null,
                fromDate: null,
                toDate: null,
                category: null,
                seller: null,
                statuses: ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"],
                firstRun: true
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                    return {
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        field: $stateParams.field,
                        fromDate: $stateParams.fromDate,
                        toDate: $stateParams.toDate,
                        category: $stateParams.category,
                        seller: $stateParams.seller,
                        statuses: $stateParams.statuses,
                        firstRun: $stateParams.firstRun
                    };
                }],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('unitType');
                    $translatePartialLoader.addPart('productStatus');
                    $translatePartialLoader.addPart('visibleState');
                    $translatePartialLoader.addPart('productVariant');
                    $translatePartialLoader.addPart('productOptionValue');
                    $translatePartialLoader.addPart('productOption');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        })
        .state('product-detail', {
            parent: 'entity',
            url: '/product/{id}',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER'],
                pageTitle: 'bozorakaAdminApp.product.detail.title'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/product/product-detail.html',
                    controller: 'ProductDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('unitType');
                    $translatePartialLoader.addPart('productStatus');
                    $translatePartialLoader.addPart('visibleState');
                    return $translate.refresh();
                }],
                entity: ['$stateParams', 'Product', function($stateParams, Product) {
                    return Product.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'product',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('product-detail.edit', {
            parent: 'product-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-dialog.html',
                    controller: 'ProductDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('product.delete', {
            parent: 'product',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/product/product-delete-dialog.html',
                    controller: 'ProductDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Product', function(Product) {
                            return Product.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('product', null, { reload: 'product' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
            .state('product.new', {
                parent: 'product',
                url: '/new',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.product.detail.title'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/product-edit.html',
                        controller: 'ProductEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: function () {
                        return {
                            nameEng: null,
                            nameUzb: null,
                            nameRus: null,
                            descriptionEng: null,
                            descriptionUzb: null,
                            descriptionRus: null,
                            shippingEng: null,
                            shippingUzb: null,
                            shippingRus: null,
                            purchasePrice: null,
                            retailPrice: null,
                            salePrice: null,
                            salePercent: null,
                            saleStartDate: null,
                            saleEndDate: null,
                            availableDate: null,
                            unit: null,
                            unitType: 'PACK',
                            status: null,
                            shippingFee: 0,
                            isNew: null,
                            weight: null,
                            length: null,
                            width: null,
                            height: null,
                            availableCount: null,
                            sortOrder: null,
                            visibleState: null,
                            id: null,
                            isCombined: true,
                            hasDiscount: false,
                            vatIncluded: true,
                            isHalalCertified: false,
                            minOrderQuantity: 1,
                            partnerMinOrderQuantity: 1,
                            categories: []
                        };
                    }
                }
            })
            .state('product.edit', {
                parent: 'product',
                url: '/{id}/edit',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER', 'ROLE_CONTENT_EDITOR_MOBPAY', 'ROLE_CONTENT_EDITOR_BOZORAKA', 'ROLE_MARKET_MANAGER'],
                    pageTitle: 'product.home.createOrEditLabel'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/product-edit.html',
                        controller: 'ProductEditController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('productPriceChangeLog');
                        $translatePartialLoader.addPart('productCloudImage');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }],
                    entity: ['$stateParams', 'Product', function ($stateParams, Product) {
                        return Product.get({id: $stateParams.id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'product',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('inventory', {
                parent: 'entity',
                url: '/inventory?page&productName&sku&isNameExclude&sort',
                data: {
                    authorities: ['ROLE_MANAGER', 'ROLE_ADMIN', 'ROLE_MARKET_MANAGER', 'ROLE_SELLER', 'ROLE_WAREHOUSE_MANAGER'],
                    pageTitle: 'product.inventory'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/product/inventory.html',
                        controller: 'InventoryController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'sku,asc',
                        squash: true
                    },
                    isNameExclude: null,
                    productName: null,
                    category: null,
                    vendors: null,
                    sku: null,
                    barcode: null,
                    totalQuantity: null,
                    purchasePrice: null,
                    retailPrice: null,
                    stockStatus: null,
                    tempSku: "",
                    tempBarcode: null,
                    shippingTag: null,
                    name: "",
                    variantName: "",
                    statuses: ["AVAILABLE", "OUT_OF_STOCK", "SOLD_OUT"],
                    firstRun: true,
                    parentCategoryField: null,
                    childCategoryField: null,
                    vendorsList: null,
                    parentCategories: null,
                    childCategories: null,
                    childCategoryList: []
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            productName: $stateParams.productName,
                            isNameExclude: $stateParams.isNameExclude,
                            category: $stateParams.category,
                            vendors: $stateParams.vendors,
                            sku: $stateParams.sku,
                            barcode: $stateParams.barcode,
                            statuses: $stateParams.statuses,
                            firstRun: $stateParams.firstRun,
                            parentCategoryField: $stateParams.parentCategoryField,
                            childCategoryField: $stateParams.childCategoryField,
                            vendorsList : $stateParams.vendorsList,
                            parentCategories: $stateParams.parentCategories,
                            childCategories: $stateParams.childCategories,
                            childCategoryList: $stateParams.childCategoryList
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('product');
                        $translatePartialLoader.addPart('productStatus');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });
    }

})();
